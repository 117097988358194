<template>
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div
        class="flex grow flex-col gap-y-5 overflow-y-auto bg-white dark:bg-gray-900 px-6 pb-4 border-r border-gray-200 dark:border-gray-700">
        <div class="flex h-16 shrink-0 items-center">
            <NuxtLink to="/">
                <LogoImage class="h-5" />
            </NuxtLink>
        </div>
        <nav class="flex flex-1 flex-col">
            <ul
                role="list"
                class="flex flex-1 flex-col gap-y-7">
                <li
                    v-for="section in sidebarMenu"
                    v-show="section.ability ? $can(section.ability[0], section.ability[1]) : true"
                    :key="section.title">
                    <div
                        v-if="section.title !== 'default'"
                        class="text-xs font-semibold leading-6 text-gray-400 mb-2">
                        {{ $t(section.title) }}
                    </div>
                    <ul
                        role="list"
                        class="-mx-2 space-y-1">
                        <li
                            v-for="item in section.menu"
                            v-show="item.ability ? $can(item.ability[0], item.ability[1]) : true"
                            :key="item.title">
                            <ModuleScope :module="item.module">
                                <NuxtLink
                                    v-if="!item.children"
                                    :to="localeRoute(item.path)"
                                    class="group"
                                    :class="['text-gray-700 dark:text-gray-300 hover:text-primary-500 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']"
                                    @click="layoutStore.isSidebarOpen = false">
                                    <Icon
                                        v-if="item.icon"
                                        :name="item.icon"
                                        :class="['group-[.router-link-active]:text-primary-500 text-gray-400 group-hover:text-primary-500', 'h-6 w-6 shrink-0']"
                                        aria-hidden="true" />
                                    <span
                                        v-else
                                        class="group-[.router-link-active]:text-primary-500 group-[.router-link-active]:border-primary-500"
                                        :class="['text-gray-400 border-gray-200 group-hover:border-primary-500 group-hover:text-primary-500', 'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white']">{{
                                            item.initial
                                        }}</span>
                                    <span class="group-[.router-link-active]:text-primary-500 dark:group-[.router-link-active]:text-white">
                                        {{ $t(item.title) }}
                                    </span>
                                </NuxtLink>
                                <Disclosure
                                    v-else
                                    v-slot="{ open }"
                                    :default-open="useRouter().currentRoute?.value?.matched?.some((route) => route.name === item.path)"
                                    as="div">
                                    <DisclosureButton
                                        class="w-full text-gray-700 dark:text-gray-300 hover:text-primary-500 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800 group rounded-md p-2 text-sm leading-6 font-semibold text-gray-700 dark:text-gray-300]">
                                        <NuxtLink
                                            as="div"
                                            :to="localeRoute(item.path)"
                                            class="group pointer-events-none flex items-center w-full text-left gap-x-3 "
                                            @click.prevent>
                                            <Icon
                                                :name="item.icon"
                                                class="h-6 w-6 shrink-0 text-gray-400 group-[.router-link-active]:text-primary-500 group-hover:text-primary-500 transition-colors duration-150 ease-in-out"
                                                aria-hidden="true" />
                                            <span class="group-[.router-link-active]:text-primary-500 dark:group-[.router-link-active]:text-white">
                                                {{ $t(item.title) }}
                                            </span>
                                            <Icon
                                                name="heroicons:chevron-right"
                                                :class="[open ? 'rotate-90' : '', 'group-[.router-link-active]:text-primary-500 ml-auto h-5 w-5 shrink-0 transition-transform duration-150 ease-in-out']"
                                                aria-hidden="true" />
                                        </NuxtLink>
                                    </DisclosureButton>
                                    <DisclosurePanel
                                        as="div"
                                        class="mt-1 px-2">
                                        <ul>
                                            <li
                                                v-for="subItem in item.children"
                                                v-show="subItem.ability ? $can(subItem.ability[0], subItem.ability[1]) : true"
                                                :key="subItem.title">
                                                <ModuleScope :module="subItem.module || item.module">
                                                    <NuxtLink
                                                        :to="localeRoute(subItem.path)"
                                                        class="group text-gray-700 dark:text-gray-300 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800 block rounded-md py-2 pr-2 pl-9 text-sm leading-6"
                                                        @click="layoutStore.isSidebarOpen = false">
                                                        <span
                                                            class="group-[.router-link-active]:font-semibold">
                                                            {{ $t(subItem.title) }}
                                                        </span>
                                                    </NuxtLink>
                                                </ModuleScope>
                                            </li>
                                        </ul>
                                    </DisclosurePanel>
                                </Disclosure>
                            </ModuleScope>
                        </li>
                    </ul>
                </li>
            </ul>

            <div class="flex gap-6 border-t pt-4">
                <a href="/app/telescope" target="_blank">
                    <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80"><path d="M0 40a39.87 39.87 0 0 1 11.72-28.28A40 40 0 1 1 0 40zm34 10a4 4 0 0 1-4-4v-2a2 2 0 1 0-4 0v2a4 4 0 0 1-4 4h-2a2 2 0 1 0 0 4h2a4 4 0 0 1 4 4v2a2 2 0 1 0 4 0v-2a4 4 0 0 1 4-4h2a2 2 0 1 0 0-4h-2zm24-24a6 6 0 0 1-6-6v-3a3 3 0 0 0-6 0v3a6 6 0 0 1-6 6h-3a3 3 0 0 0 0 6h3a6 6 0 0 1 6 6v3a3 3 0 0 0 6 0v-3a6 6 0 0 1 6-6h3a3 3 0 0 0 0-6h-3zm-4 36a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM21 28a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path></svg>
                </a>
                <a href="/app/horizon" target="_blank">
                    <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path d="M5.26176342 26.4094389C2.04147988 23.6582233 0 19.5675182 0 15c0-4.1421356 1.67893219-7.89213562 4.39339828-10.60660172C7.10786438 1.67893219 10.8578644 0 15 0c8.2842712 0 15 6.71572875 15 15 0 8.2842712-6.7157288 15-15 15-3.716753 0-7.11777662-1.3517984-9.73823658-3.5905611zM4.03811305 15.9222506C5.70084247 14.4569342 6.87195416 12.5 10 12.5c5 0 5 5 10 5 3.1280454 0 4.2991572-1.9569336 5.961887-3.4222502C25.4934253 8.43417206 20.7645408 4 15 4 8.92486775 4 4 8.92486775 4 15c0 .3105915.01287248.6181765.03811305.9222506z"></path></svg>
                </a>
                <a href="/app/pulse" target="_blank">
                    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4566 6.75005C15.9683 6.75596 16.4047 7.09621 16.5001 7.56364L18.8747 19.2038L19.999 17.1682C20.1832 16.8347 20.5526 16.625 20.9559 16.625H31.1744C31.7684 16.625 32.25 17.0727 32.25 17.625C32.25 18.1773 31.7684 18.625 31.1744 18.625H21.6127L19.3581 22.7068C19.1483 23.0867 18.7021 23.3008 18.2475 23.2397C17.7928 23.1786 17.4301 22.8559 17.3445 22.4363L15.376 12.7868L13.1334 22.4607C13.0282 22.9146 12.6007 23.2414 12.1013 23.2498C11.6019 23.2582 11.162 22.9458 11.0393 22.4957L9.30552 16.1378L8.19223 18.0929C8.00581 18.4202 7.64002 18.625 7.2416 18.625H1.32563C0.731576 18.625 0.25 18.1773 0.25 17.625C0.25 17.0727 0.731576 16.625 1.32563 16.625H6.59398L8.71114 12.9071C8.9193 12.5415 9.34805 12.3328 9.78979 12.3821C10.2315 12.4313 10.5951 12.7283 10.7044 13.1292L11.9971 17.8695L14.3918 7.53929C14.4996 7.07421 14.9449 6.74414 15.4566 6.75005Z" fill="url(#paint0_linear_4_31)"></path>
                        <defs>
                            <linearGradient id="paint0_linear_4_31" x1="16.25" y1="6.74997" x2="16.25" y2="23.25" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#F85A5A"></stop>
                                <stop offset="0.828125" stop-color="#7A5AF8"></stop>
                            </linearGradient>
                        </defs>
                    </svg>
                </a>

            </div>
        </nav>
    </div>
</template>

<script setup>
import {useLayoutStore} from "@/store/layout"

const layoutStore = useLayoutStore()
</script>